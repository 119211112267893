<script setup>
import { useRoute } from "vue-router";
import { useMainStore } from "@/store";
import { useCategsStore } from "@/store/categs";
import anims from "@/utils/animFunctions";
import OtherFooter from "@/components/layout/Other/Footer/OtherFooter.vue";
import OtherHeader from "@/components/layout/Other/Header/OtherHeader.vue";
import OtherMenu from "@/components/layout/Other/Menu/OtherMenu.vue";
import VideoPopup from "@/components/global/Updated/VideoPopup.vue";

import Scrollbar from "smooth-scrollbar";

const store = useMainStore();
const storeCategs = useCategsStore();
const route = useRoute();

const initAnim = useNuxtApp().$initAnim;

const isMenu = ref(false);
const openedMenu = ref(false);
const linkList = ref([]);

const videoPopupShow = computed(() => {
  // if (import.meta.client && store.isMobile) {
  //   const html = document.querySelector("html");
  // }
  return store.videoPopupShow;
});

const openMenu = () => {
  setTimeout(() => {
    isMenu.value = true;
  }, 100);
  store.changeFooter(false);
  store.setMenuStatus(true);

  setTimeout(() => {
    openedMenu.value = true;
  }, 500);
  if (store.isMobile) {
    store.setScrollHeight(window.scrollY);
  }
  anims.destroySubNavbar();
};

const closeMenu = () => {
  openedMenu.value = false;
  setTimeout(() => {
    isMenu.value = false;
    store.setMenuStatus(false);
  }, 500);
  store.changeFooter(true);
  anims.animateSubNavbar();
  setTimeout(() => {
    initScrollbar();
  }, 800);
  setTimeout(() => {
    initAnim();
  }, 1500);
};

const initScrollbar = () => {
  const pageEl = document.querySelector(".inner-page");

  if (!store.isMobile) {
    Scrollbar.init(pageEl, { damping: 0.07 });
  }
};

onMounted(() => {
  initScrollbar();
  updateLinkList();
  setTimeout(() => {
    initAnim();
  }, 1000);
  setTimeout(() => {
    anims.animateSubNavbar();
  }, 1500);
  setTimeout(() => {
    anims.animateHeader();
  }, 2500);
});

const updateLinkList = () => {
  const slug = route.path.split("/")[1];

  if (storeCategs.getOnlyMenusPages().hasOwnProperty(slug)) {
    linkList.value = storeCategs.getOnlyMenusPages()[slug];
  } else {
    linkList.value = [];
  }
};

watch(
  () => route.fullPath,
  (newPath) => {
    if (isMenu.value) {
      isMenu.value = false;
      openedMenu.value = false;
      store.setMenuStatus(false);

      linkList.value = [];
    }
    if (newPath !== "/") {
      updateLinkList();
    } else {
      setTimeout(() => {
        linkList.value = [];
      }, 100);
    }

    setTimeout(() => {
      const pageEl = document.querySelector(".inner-page");

      if (!store.isMobile && pageEl) {
        Scrollbar.get(pageEl).scrollTo(0, 0);
      }
    }, 500);
  }
);
</script>

<template>
  <div class="other">
    <Transition name="fade">
      <Loading />
    </Transition>

    <header style="overflow: hidden">
      <OtherHeader
        :openedMenu="openedMenu"
        :isMenu="isMenu"
        :linkList="linkList"
        @open-menu="openMenu"
        @close-menu="closeMenu"
      />
    </header>

    <Transition name="fadeMenu">
      <OtherMenu v-if="openedMenu" style="padding-top: 100px" />
    </Transition>

    <Transition name="fade">
      <div v-show="!isMenu" class="inner-page">
        <main>
          <NuxtPage />
        </main>

        <Transition name="fadeFooter">
          <footer v-if="store.isFooter">
            <OtherFooter />
          </footer>
        </Transition>
      </div>
    </Transition>

    <Transition name="popupFade">
      <VideoPopup v-if="videoPopupShow" />
    </Transition>
  </div>
</template>

<style lang="scss">
.popupFade-enter-active,
.popupFade-leave-active {
  transition: opacity 0.3s ease;
}
.popupFade-enter-from,
.popupFade-leave-to {
  opacity: 0;
}

.popupFade-enter-active,
.popupFade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-active {
  transition: opacity 0.5s;
  transition-delay: 0.5s;
}

.fade-leave-active {
  transition: opacity 0.5s;
  transition-delay: 0s;
}

.fadeFooter-leave-active {
  transition: opacity 0.5s;
  opacity: 0;
}

.fadeFooter-enter-to {
  transition: opacity 0.5s;
}

.fadeFooter-enter-to,
.fadeFooter-leave-to {
  opacity: 0;
}

@include adaptive(tablet) {
  footer {
    padding-bottom: 30px;
  }
}
</style>
